<template>
  <div class="product-container">
    img
    <!-- banner_cp -->
    <div class="banner_cp" style="background-color: #f6f6f6">
      <div class="margin width1480 hidden">
        <div class="l">
          <dl style="display: block">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-site-imgs/kaizuozhem/www.yalijuda.com_%E6%8C%91%E6%88%98%E8%80%85%E4%B8%BB%E9%A1%B5%E5%9B%BE_Ggb9IUcxPl.png"
            />
          </dl>
        </div>
        <div class="r">
          <div class="bg a-fdB">
            <dd>挑 战 者</dd>
            <dl>
              <p>外形轻薄小巧 | 车型全能适配 | 支持预约充电</p>
            </dl>
            <ol class="f">
              <dt class="o">
                <span><i style="background-color: #ffffff"></i></span>
                <p>白色</p>
              </dt>
            </ol>
            <div class="kaituozhe-flex-div">
              <a @click="$U.openProductFile('tiaozhanzhe')">产品手册</a>
              <a class="movie-view-a" @click="viewVideoFun()">观看视频</a>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div class="full-img-div">
      <img
        src="https://img.cdn.wxzhida.cn/shzd-web-site/tiaozhanzhe/tiaozhanzhe-full-01.jpg"
        class="full-img"
      />
    </div>

    <div class="full-img-div">
      <img
        src="https://img.cdn.wxzhida.cn/shzd-web-site/tiaozhanzhe/tiaozhanzhe-full-02.jpg"
        class="full-img"
      />
    </div>

    <!-- shz_nhj -->
    <!-- <div id="g2">
      <div
        class="shz_nhj"
        style="
          background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_nhj_bg.jpg);
        "
      >
        <div class="margin width1480 hidden">
          <div class="t">
            <dd>耐候级PC+ABS材质外壳</dd>
            <dl>防雨防冻耐高温耐老化，经受室外气候的考验</dl>
          </div>
          <div class="f">
            <img
              src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_nhj.png"
            />
          </div>
        </div>
      </div>
    </div> -->
  </div>
  <!-- cp_aqfh -->
  <!-- <div id="g3">
    <div class="margin width1480 hidden">
      <ul class="cp_aqfh">
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh1.png"
              />
            </ol>
            <dd>过压/欠压保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh2.png"
              />
            </ol>
            <dd>过流保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh3.png"
              />
            </ol>
            <dd>雷电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh4.png"
              />
            </ol>
            <dd>接地保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh5.png"
              />
            </ol>
            <dd>防漏电保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh6.png"
              />
            </ol>
            <dd>短路保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh7.png"
              />
            </ol>
            <dd>环境电源异常数据记录</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh8.png"
              />
            </ol>
            <dd>IP54</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh9.png"
              />
            </ol>
            <dd>过温保护</dd>
          </div>
        </li>
        <li>
          <div class="bg">
            <ol>
              <img
                src="https://img.cdn.wxzhida.cn/shzd-web-site/images-new/cp_aqfh10.png"
              />
            </ol>
            <dd>预约充电</dd>
          </div>
        </li>
      </ul>
    </div>
  </div> -->
  <!-- jg_gscd -->
  <!-- <div id="g4">
    <div
      class="shz_jzxx"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jzxx_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd class="cp_bt_d">紧致小巧，使用便捷</dd>
          <dl class="cp_bt_y">
            适用于国内推出的纯电动和插电式油电混合动力汽车
          </dl>
        </div>
        <div class="f">
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jzxx.png"
            class="c"
          />
          <img
            src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jzxx_y.png"
            class="y"
          />
        </div>
      </div>
    </div>
  </div> -->
  <!-- jg_znjc -->
  <div id="g5">
    <div class="shz_znjc_tit">
      <dd class="cp_bt_d">智能交互 随心来电</dd>
      <dl class="cp_bt_y">三种充电模式</dl>
    </div>
    <div
      class="shz_yzyk"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/tiaozhanzhe/tiaozhanzhe-charge-bg.png);
      "
    >
      <div class="margin width1480 hidden">
        <img
          src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_yzyk.png"
          class="tp"
        />
        <div class="r">
          <dd class="cp_bt_d">一桩一卡</dd>
          <dl class="cp_bt_y">配备防盗功能，匹配唯一身份识别智充卡启动</dl>
        </div>
      </div>
    </div>
  </div>
  <!-- xhx_zyqh -->
  <div id="g6">
    <!-- <div class="shz_gbqk" style="background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_gbqk_bg.jpg);">
      <div class="margin width1480 hidden">
          <div class="l">
              <dd class="cp_bt_d">国标7孔充电头</dd>
              <dl class="cp_bt_y">适用于大部分新能源汽车</dl>
          </div>
          <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_gbqk.png" class="tp" />
      </div>
  </div> -->
  </div>
  <!-- xhx_aqwd -->
  <div id="g7">
    <div
      class="shz_jyc"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jyc_bg.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="r">
          <dd class="cp_bt_d">TPE枪线绝缘层，高温不软化</dd>
          <dl class="cp_bt_y">出色握感，符合人体工程学设计</dl>
        </div>
      </div>
      <img
        src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_jyc.png"
        class="tp"
      />
    </div>
  </div>

  <div class="full-img-div">
    <img
      src="https://img.cdn.wxzhida.cn/shzd-web-site/tiaozhanzhe/tiaozhanzhe-full-03.jpg"
      class="full-img"
    />
  </div>
  <!-- xhx_lxbz -->
  <div id="g8">
    <div
      class="cp_f_bg"
      style="
        background-image: url(https://img.cdn.wxzhida.cn/shzd-web-site/mp4/v1/tianzhanzhe-charge-bg-02.jpg);
      "
    >
      <div class="margin width1480 hidden">
        <div class="t">
          <dd>智慧出行 与你同行</dd>
          <dl>
            安装网络覆盖全国360个城市,已为100万户家庭生产并安装了家庭充电桩！
          </dl>
          <ol>
            <a @click="$U.openProductFile('tiaozhanzhe')"
              >产品手册<i class="fa fa-angle-right"></i
            ></a>
          </ol>
        </div>
        <!-- <div class="f">
              <img src="https://img.cdn.wxzhida.cn/shzd-web-site/images/shz_zncx.png" />
          </div> -->
      </div>
    </div>

  </div>

  <div class="template-full-mask-div" v-if="state.showFullVideoFlag">
      <div class="center-view-view-div">
        <video
          controls
          name="media"
          loop="loop"
          controlsList="nodownload"
          id="myvideo"
          class="tzz_video_style"
        >
          <source
            src="https://img.cdn.wxzhida.cn/shzd-web-site/mp4/v1/%E6%8C%9A%E8%BE%BE%E6%8C%91%E6%88%98%E8%80%85%E4%BA%A4%E6%B5%81%E6%A1%A9.mp4"
            type="video/mp4"
          />
        </video>
      </div>

      <div
        class="fixed-close-icon-div"
        @click="state.showFullVideoFlag = false"
      >
        <i class="fa fa-close"></i>
      </div>
    </div>
</template>

<script>
import { getCurrentInstance, onMounted, reactive } from 'vue'
export default {
  name: 'shouhuzhe',
  components: {},
  setup() {
    const { proxy } = getCurrentInstance()

    const state = reactive({
      showFullVideoFlag: false,
    })
    const viewVideoFun = () => {
      state.showFullVideoFlag = true
    }

    onMounted(() => {
      document.title = '挑战者'
      // 滚动到页面最上方
      window.scrollTo(0, 0)
      $('.banner_cp .width1480 .r ol.f dt').click(function () {
        var liindex = $('.banner_cp .width1480 .r ol.f dt').index(this)
        $(this).addClass('o').siblings().removeClass('o')
        $('.banner_cp .width1480 .l dl')
          .eq(liindex)
          .fadeIn(150)
          .siblings('dl')
          .hide()
      })
      $(window).scroll(function () {
        //为页面添加页面滚动监听事件
        var wst = $(window).scrollTop() //滚动条距离顶端值
        for (var i = 1; i < 20; i++) {
          if ($('#g' + i) && $('#g' + i).offset()) {
            //加循环
            if ($('#g' + i).offset().top <= wst) {
              //判断滚动条位置
              $('#g' + i).addClass('on') //给当前导航加c类
            } else {
              //$("#p"+i).removeClass('on');
            }
          }
        }
      })
    })
    return {
      state,
      viewVideoFun,
    }
  },
}
</script>

<style lang="less" scoped>
.tzz_video_style {
  width: 100%;
}

.video-bg-dl {
  padding-top: 150px;
}

.video-bg-dl a {
  position: absolute;
  top: 0px;
  bottom: 0px;
  right: 0px;
  left: 0px;
  color: #ffffff;
  display: flex;
  text-align: center;
}

.video-bg-dl ol {
  font-size: 1.5rem;
  position: relative;
  display: flex;
  width: 666px;
  justify-content: center;
  margin-top: 330px;
}

.video-bg-dl ol i {
  transition: all 0.5s ease;
  border: 2px solid #ffffff;
  position: absolute;
  width: 2.875rem;
  height: 3.375rem;
  line-height: 3.375rem;
  border-radius: 50%;
  font-weight: 300;
  padding-left: 0.5rem;
}

.kaituozhe-flex-div {
  display: flex;
  align-items: center;
  justify-content: center;
}

.kaituozhe-flex-div a {
  margin: 2.5vh 20px 0px !important;
}

.kaituozhe-flex-div .movie-view-a {
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
  font-weight: 600;
}
.full-img-div {
  margin-top: -10px;
  overflow: hidden;
}

.full-img{
  width: 100%;
}


.margin-bottom--10 {
  margin-bottom: -10px;
}
.movie-view-a {
  color: #364166 !important;
  background-color: #ffffff !important;
  border: 2px solid #364166 !important;
  font-weight: 600;
}

.template-full-mask-div {
  position: fixed;
  left: 0;
  right: 0;
  top: 0;
  bottom: 0;
  background-color: #000000d4;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 10086;
}

.center-view-view-div {
  width: 80%;
  height: calc(80% - 88px);
  display: flex;
  justify-content: center;
  align-items: center;
}

.kaituozhe_video_style {
  z-index: 10089;
  width: 100%;
  height: 100%;
}

.fixed-close-icon-div {
  position: fixed;
  right: 32px;
  top: 32px;
  color: #f2f2ff;
  cursor: pointer;
  font-size: 2rem;
  font-weight: 400;
}
</style>
